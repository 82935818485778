exports.components = {
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-delivery-index-jsx": () => import("./../../../src/pages/delivery/index.jsx" /* webpackChunkName: "component---src-pages-delivery-index-jsx" */),
  "component---src-pages-maintenance-index-jsx": () => import("./../../../src/pages/maintenance/index.jsx" /* webpackChunkName: "component---src-pages-maintenance-index-jsx" */),
  "component---src-pages-transport-index-jsx": () => import("./../../../src/pages/transport/index.jsx" /* webpackChunkName: "component---src-pages-transport-index-jsx" */),
  "component---src-templates-1-top-index-jsx": () => import("./../../../src/templates/1_top/index.jsx" /* webpackChunkName: "component---src-templates-1-top-index-jsx" */),
  "component---src-templates-2-about-index-jsx": () => import("./../../../src/templates/2_about/index.jsx" /* webpackChunkName: "component---src-templates-2-about-index-jsx" */),
  "component---src-templates-3-service-index-jsx": () => import("./../../../src/templates/3_service/index.jsx" /* webpackChunkName: "component---src-templates-3-service-index-jsx" */),
  "component---src-templates-5-privacy-index-jsx": () => import("./../../../src/templates/5_privacy/index.jsx" /* webpackChunkName: "component---src-templates-5-privacy-index-jsx" */),
  "component---src-templates-6-contact-index-jsx": () => import("./../../../src/templates/6_contact/index.jsx" /* webpackChunkName: "component---src-templates-6-contact-index-jsx" */)
}

